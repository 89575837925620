import React, { useEffect, useState } from "react"
import { graphql } from "gatsby"

import Meta from "../components/addon/meta"
import Layout from "../components/layout/layout"
// import HeadSection from "../components/pageSections/aboutManagement/headSection"
import ManagementTab from "../components/pageSections/aboutManagement/managementTab"
import OrgSetupTab from "../components/pageSections/aboutManagement/orgSetupTab"
import ImageComponent from "../components/pageSections/imageComponent"

import useDeviceDetect from "../service/useDeviceDetect"

const AboutManagement = ({
  data: {
    allNodeManagementMemberProfile: { edges },
    allNodeTemplatePages: { edges: node },
  },
}) => {
  const tabData = edges
  const rawData = node
  const metaTag = Array.isArray(rawData[0]?.node?.metatag) && rawData[0]?.node?.metatag.length > 0 ? rawData[0]?.node?.metatag : [];

  const bom = tabData
    .filter(t => t.node?.field_tab === "Board of Management")
    .sort((a, b) => {
      const x = a?.node?.field_weight
      const y = b?.node?.field_weight
      return x - y
    })
  const ec = tabData
    .filter(t => t.node?.field_tab === "Executive Committee")
    .sort((a, b) => {
      const x = a?.node?.field_weight
      const y = b?.node?.field_weight
      return x - y
    })
  const { isMobile } = useDeviceDetect()
  let [imgSrc, setImgSrc] = useState("")
  const [altText, setAltText] = useState("")
  let [modalData, setModalData] = useState({})
  // const defaultImageSrc = isMobile === true ? "https://via.placeholder.com/157x158" : "https://via.placeholder.com/232x289"
  const defaultImageSrc =
    isMobile === true
      ? "https://via.placeholder.com/330x411"
      : "https://via.placeholder.com/300x374"

  const onCardClick = data => {
    if (data) {
      const image =
        isMobile === true
          ? data.relationships?.modal_image_mobile?.uri?.url
          : data.relationships?.modal_image?.uri?.url
      setImgSrc(image)
      const alt = data?.image?.alt || "Hinduja Hospital"
      setModalData(data)
      setAltText(alt)
    } else setModalData({})
  }

  return (
    <Layout>
      <Meta
        files={
          {
            js: [],
            css: ["/assets/css/about.css"]
          }
        }
        tags={metaTag}
      />
      <main className="innerpage">
        {/* <HeadSection /> */}
        <section className="section_bg about_section_inner">
          <div className="container">
            <ul className="nav nav-tabs gallery-tabs" role="tablist">
              {bom?.length > 0 &&
              <li role="presentation" className="nav-item">
                <a
                  className="nav-link active"
                  data-toggle="tab"
                  href="#Board_Of_Management"
                  role="tab"
                  aria-controls="Board_Of_Management"
                  aria-selected="false"
                >
                  Board Of Management
                </a>
              </li>
              }
              {ec?.length > 0 &&
                <li role="presentation" className="nav-item">
                    <a className="nav-link" data-toggle="tab" href="#Executive_Committee" role="tab" aria-controls="Executive_Committee" aria-selected="true">Executive Committee</a>
                </li>
              }
            </ul>
            <div className="tab-content mt-3">
              {bom?.length > 0 && (
                <div
                  className="tab-pane active"
                  id="Board_Of_Management"
                  role="tabpanel"
                  aria-labelledby="Board_Of_Management"
                >
                  <div className="row mgmt_row">
                    {bom.map((tab, i) => {
                      let imageSrc =
                        tab?.node?.relationships?.field_desktop_image?.uri?.url
                      let altText = tab?.node?.field_desktop_image?.alt || ""
                      let name = tab?.node?.title || ""
                      let position = tab?.node?.field_position || ""
                      return (
                        <div className="col-md-4" key={i}>
                          <div
                            className="mgmt_card"
                            data-toggle="modal"
                            data-target="#modal_ap_hinduja"
                          >
                            <figure>
                              {imageSrc ? (
                                <img src={imageSrc} alt={altText} />
                              ) : (
                                <img
                                  className="lazyload"
                                  src={defaultImageSrc}
                                  alt={altText}
                                />
                              )}
                              <figcaption>
                                <span className="name">{name}</span>
                                <span className="desig">{position}</span>
                              </figcaption>
                            </figure>
                            <span className="arrow"></span>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )}
              {ec?.length > 0 && (
                <div
                  className="tab-pane"
                  id="Executive_Committee"
                  role="tabpanel"
                  aria-labelledby="Executive_Committee"
                >
                  <div className="row mgmt_row">
                    {ec.map((tab, i) => {
                      let imageSrc =
                        tab?.node?.relationships?.field_desktop_image?.uri?.url
                      let altText = tab?.node?.field_desktop_image?.alt || ""
                      let name = tab?.node?.title || ""
                      let position = tab?.node?.field_position || ""
                      return (
                        <div className="col-md-4" key={i}>
                          <div
                            className="mgmt_card"
                            data-toggle="modal"
                            data-target="#modal_ap_hinduja"
                          >
                            <figure>
                              {imageSrc ? (
                                <img src={imageSrc} alt={altText} />
                              ) : (
                                <img
                                  className="lazyload"
                                  src={defaultImageSrc}
                                  alt={altText}
                                />
                              )}
                              <figcaption>
                                <span className="name">{name}</span>
                                <span className="desig">{position}</span>
                              </figcaption>
                            </figure>
                            <span className="arrow"></span>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
        </section>
      </main>
      {/* <div className="modal fade modal_mgmt modal-center" id="modal_ap_hinduja" tabindex="-1" role="dialog" aria-labelledby="basicModal" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-body">
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">X</span>
                            </button>
                            <figure className="bod_img">
                                {imgSrc 
                                    ? <GatsbyImage image={imgSrc} alt={altText} />
                                    : <img src={defaultImageSrc} alt={altText} />}
                            </figure>
                            <div className="content_heading">
                                <h2>{modalData && modalData.title ? modalData.title : ''}</h2>
                                <p>{modalData && modalData.designation ? modalData.designation : ''}</p>
                            </div>
                            <div className="content_desc" dangerouslySetInnerHTML={{ __html: modalData?.about?.processed }}>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
    </Layout>
  )
}

export const query = graphql`
  query myQueryAndMyQuery {
    allNodeTemplatePages(
      filter: { path: { alias: { regex: "/board-of-management$/" } } }
    ) {
      edges {
        node {
          id

          title

          path {
            alias
          }

          body {
            processed
          }

        }
      }
    }

    allNodeManagementMemberProfile {
      edges {
        node {
          id

          title

          body {
            processed
          }


          field_tab

          field_weight

          field_position

          field_desktop_image {
            alt
          }

          relationships {
            field_desktop_image {
              uri {
                value
                url
              }
            }
          }
        }
      }
    }
  }
`
export default AboutManagement
